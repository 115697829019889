<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestionamiento de Equipos</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name: 'Check List Equipos'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader  color="primario" text-color="white">
                        <i class="fas fa-cogs"></i>
                        <span v-if="this.$route.params.id == null" class="h5"> Registrar Gestionamiento de Equipos</span>
                        <span v-else class="h5"> Actualizar Gestionamiento de Equipos</span>
                    </CCardHeader>
                    <CCardBody>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(varificarAccion)">
                                <b-row>
                                    <b-col md="12">
                                        <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                            <b-form-group label="Guia Principal:" class="mb-2">
                                                <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegSeleccEquipos.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosRegSeleccEquipos.idGuia" :options="listUsuarios" @search:blur="blurSeleccionamientoEquipos">
                                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <!-- <b-col md="12">
                                        <b-table bordered hover show-empty mediun responsive outlined :items="listEquipos" :fields="camposSelecEquipos" :select-mode="selectMode" selectable empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                            <template v-slot:cell(codigo)="data">
                                                {{ data.item.codigo }}
                                            </template>
                                            <template v-slot:cell(nombreEquipo)="data">
                                                {{ data.item.nombre }}
                                            </template>
                                            <template v-slot:cell(categoria)="data">
                                                {{ data.item.categoria }}
                                            </template>
                                            <template v-slot:cell(check)="data">
                                                {{ data.item.idEquipos }}
                                            </template>
                                        </b-table>
                                    </b-col> -->

                                    <b-col md="12" class="my-2">
                                        <CButton size="sm" class="float-right" color="dark" @click="agregarListGestionEquipo()">
                                            <i class="fas fa-plus fa-sm"></i> Agregar
                                        </CButton>
                                    </b-col>
                                    <b-col md="12">
                                        <template>
                                            <div  class="accordion" role="tablist" v-for="(opcion, k) in datosRegSeleccEquipos.listCheckListEquipo" :key="k">
                                                <b-card no-body style="z-index: 0 !important">
                                                    <b-card-header header-tag="header" class="p-1 bg-accordion" role="tab">
                                                        <div block v-b-toggle="'accordion-'+k" class="bg-accordion">
                                                            <span class=" h6">{{'List Equipo '+(k+1)}}</span>
                                                            <span class="float-right"> <i class="fas" style="font-size:1.1rem"></i></span>
                                                            <CButton v-if="datosRegSeleccEquipos.listCheckListEquipo.length >= 2" size="sm" class="float-right" color="danger" @click="quitarListGestionEquipo(k)">
                                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                            </CButton>
                                                        </div>
                                                    </b-card-header>
                                                    <b-collapse visible :id="'accordion-'+k" accordion="my-accordion" role="tabpanel">
                                                        <b-card-body>
                                                            <b-row>
                                                                <!-- <b-col md="12">
                                                                    <validation-provider name="sexo" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Sexo:" class="mb-2">
                                                                            <b-form-select :state="getValidationState(validationContext)" v-model="opcion.idEquipo" :options="[{value:1,text:'M'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'},{value:2,text:'F'}]">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="''" disabled>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col> -->

                                                                <b-col md="12">
                                                                    <validation-provider name="lista equipos" :rules="{required: true}" v-slot="validationContext">
                                                                        <b-form-group label="Seleccione el Equipo:" class="mb-2">
                                                                            <b-form-select :state="getValidationState(validationContext)"  v-model.trim="opcion.idEquipo" aria-placeholder="Seleccion" :options="listEquipos" text-field="nombre" value-field="idEquipos"  @search:blur="blurSelecEquipos">
                                                                                <template v-slot:first>
                                                                                    <b-form-select-option :value="null" disabled hidden>Seleccione una opción</b-form-select-option>
                                                                                </template>
                                                                            </b-form-select>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                
                                                                <!-- <b-col md="12"  style="z-index: 1 !important">
                                                                    <validation-provider name="lista equipos" rules="required" v-slot="{errors}" style="z-index: 1 !important">
                                                                        <b-form-group label="Equipos Principal:" class="mb-2" style="z-index: 1">
                                                                            <v-select style="z-index: 1 !important" :reduce="listEquipos => listEquipos.idEquipos" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': opcion.idEquipo , 'style-invalid-select is-invalid': !!errors.length }" v-model.trim="opcion.idEquipo" :options="listEquipos" @search:blur="blurSelecEquipos">
                                                                                <span  slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                            </v-select>
                                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col> -->
                                                                <b-col md="6">
                                                                    <validation-provider name="tipo estado" rules="required" v-slot="{errors}">
                                                                        <b-form-group label="Tipo Estado:" class="mb-2">
                                                                            <v-select :reduce="listTipEstado => listTipEstado.id" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': opcion.idTipoEstado , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="opcion.idTipoEstado" :options="listTipEstado" @search:blur="blurRegTipoEstado">
                                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                            </v-select>
                                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="6">
                                                                    <validation-provider name="cantidad" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="validationContext">
                                                                        <b-form-group label="Cantidad:" class="mb-2">
                                                                            <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese la cantidad" v-model.trim="opcion.cantidad"></b-form-input>                                    
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                                <b-col md="12">
                                                                    <validation-provider name="observacion" :rules="{required: false}" v-slot="validationContext">
                                                                        <b-form-group label="Observación (Opcional):" class="mb-2">
                                                                            <b-form-textarea v-model.trim="opcion.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la Observación" rows="3" max-rows="6"></b-form-textarea>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-form-group>
                                                                    </validation-provider>
                                                                </b-col>
                                                            </b-row>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </template>
                                    </b-col>
                                    <b-col md="12" class="text-center my-2">
                                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                            Guardar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </CCardBody>
                </CCard>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbUsuario = firebase.firestore().collection('users');
const dbEquipos = firebase.firestore().collection('checkListEquipos');
const dbTipEstado = firebase.firestore().collection('tipoEstado');
const dbGestionEquipos = firebase.firestore().collection('gestionEquipos');

export default {
    data() {
        return {
            listUsuarios: [],
            listEquipos: [],
            listSelecEquipos: [],
            listTipEstado: [],
            disabled: false,

            selectMode: 'multi',

            datosRegSeleccEquipos: {
                idGuia: '',
                listCheckListEquipo: [
                    {
                        idEquipo: '',
                        cantidad: '',
                        idTipoEstado: '',
                        observacion: '',
                    }
                ]
            },

            camposSelecEquipos: [
                {
                    key: "codigo",
                    label: "Codigo",
                    class: "text-center",
                },
                {
                    key: "nombreEquipo",
                    label: "Nombre del Equipo",
                    class: "text-center",
                },
                {
                    key: "categoria",
                    label: "Categoria",
                    class: "text-center",
                },
                {
                    key: "check",
                    label: "Check",
                    class: "text-center",
                },
            ],
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        blurSeleccionamientoEquipos() {
            this.computedForms.refs['guia principal'].validate();
        },
        blurSelecEquipos() {
            this.computedForms.refs['lista equipos'].validate();
        },
        blurRegTipoEstado() {
            this.computedForms.refs['tipo estado'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });

        },
        listarEquipos() {
            let me = this;
            dbEquipos
            .where("estado","==", 2)
            .where("idCliente","==",String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listEquipos = [];
                querySnapshot.forEach((doc) => {
                    me.listEquipos.push({
                        idEquipos: doc.id,
                        codigo: doc.data().codigo,
                        nombre: doc.data().nombre,
                        idCategoria: doc.data().idCategoria,
                        idTipoEstado: doc.data().idTipoEstado,
                        cantidad: doc.data().cantidad,
                        fechaCreacion: doc.data().fechaCreacion,
                    });
                });
                me.listEquipos.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        listarEstado() {
            let me = this;

            dbTipEstado
            .where("estado","==", 2)
            .onSnapshot((querySnapshot) => {
                me.listTipEstado = [];
                querySnapshot.forEach((doc) => {
                    me.listTipEstado.push({
                        id: doc.id,
                        nombre: doc.data().nombre,
                    });
                });
                
            });
        },

        varificarAccion() {
            if (this.$route.params.id == null) {
                this.registrarGestionEquipos();
            } else if (this.$route.params.id != null) {
                this.actualizarGestionEquipos();
            }
        },
        agregarListGestionEquipo() {
            let me = this;
            me.datosRegSeleccEquipos.listCheckListEquipo.push({
                idEquipo: '',
                cantidad: '',
                idTipoEstado: '',
                observacion: '',
            });
        },

        quitarListGestionEquipo(k) {
            let me = this;
            me.datosRegSeleccEquipos.listCheckListEquipo.splice(k, 1);
        },
        registrarGestionEquipos() {
            let me = this;
            me.disabled = true;

            dbGestionEquipos.add({
                idGuia: me.datosRegSeleccEquipos.idGuia,
                listCheckListEquipo: me.datosRegSeleccEquipos.listCheckListEquipo,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                estado: 2,
                idCliente: me.$store.state.user.idCliente.trim(),
            }).then((check) =>{
                me.swat('success', 'Registrado satisfactoriamente')
                me.$router.push({
                    name: 'Check List Equipos'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        obtenerGestionEquipos() {
            let me = this;

            me.disabled = true;

            dbGestionEquipos.doc(me.$route.params.id).get()
            .then((doc) => {
                me.datosRegSeleccEquipos.idGuia = doc.data().idGuia;
                me.datosRegSeleccEquipos.listCheckListEquipo = doc.data().listCheckListEquipo;
                me.disabled = false;
            }).catch((error)=>{
                me.swat('error', 'Algo salió mal!');
                me.disabled = false;
            });
        },
        actualizarGestionEquipos() {
            let me = this;
            me.disabled = true;

            dbGestionEquipos.doc(me.$route.params.id).update({
                idGuia: me.datosRegSeleccEquipos.idGuia,
                listCheckListEquipo: me.datosRegSeleccEquipos.listCheckListEquipo,
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then(() => {
                me.swat('success', 'Modificado satisfactoriamente');
                me.$router.push({
                    name: 'Check List Equipos'
                });
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!')
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarEstado();
            this.listarEquipos();
            if(this.$route.params.id != null){
                this.obtenerGestionEquipos();
            }
        }
    }
}
</script>

<style scoped>
 .vs__dropdown-menu {
     position: relative !important
 }
</style>